import styled from 'styled-components'
import Img from 'gatsby-image'
import MEDIA from 'helpers/mediaTemplates';
import { blue, orange, grey, } from "../../../constants/theme"


export const Container = styled.div`
background-image: ${(props) => props.bg ? `linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.7)), url(${props.bg})` : "none"};
background-repeat: no-repeat;
background-size: cover;
background-position: center;
height: 100%;
width: 100%;
margin-top: 2.5rem;
padding-top: 5rem;
border-top: 3px solid grey;
max-width: 1200px;
font-size: 2rem;
padding-bottom: 0;
margin-bottom: 7.5rem;
z-index: 5;


${MEDIA.L_TABLET`
margin-bottom: -6rem;
  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
  border-top: 2.5px solid grey;
  `};

  ${MEDIA.M_PHONE`
  padding-top:2.5rem;
  border-top: 1.5px solid grey;

  `};

  ${MEDIA.S_PHONE`

  `};
`

// export const Overlay = st



export const Author = styled.p`
font-size: 1.66rem;
margin: 0 auto;
font-weight: 600;
width: auto;
// background: red;
font-family: 'Termina';
display: flex;
align-items: center;
text-transform: uppercase;
margin-left: 8rem;
z-index: 10;

color: ${orange};
padding-top: 1.5rem;
a {
  text-shadow: 1px 1px 2.5px ${blue};
    padding-left: .33rem;
     color: grey;
    font-weight: 700;
    margin-left: .33rem;
    text-decoration: none;
    letter-spacing: .33rem;
    font-size: 2rem;
     &:hover{
         cursor: pointer;
         color: ${blue}
     }
}
br {
    display: none;
}

${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
font-size: 1.25rem;
margin-left: 5rem;
padding-top: .75rem;
a {
    padding-left: .75rem;
    font-size: 1.75rem;
}
br {
    display: block;
}
  `};

  ${MEDIA.M_PHONE`
font-size: 1.15rem;
padding-top: .33rem;
margin-left: 2rem;
text-shadow: 1px 1.5px 1.5px ${blue};

a {
  text-shadow: 1px 1.5px 1.5px ${blue};
    font-size: 1.5rem;
}
  `};

  ${MEDIA.S_PHONE`
margin-left: 1rem;
font-size: 1rem;
a {
    font-size: 1.25rem;
}
  `};
`

export const AuthorPic = styled.img`
// background: black;
width: 15rem;
height: 15rem;
border-radius: 3rem;
position: relative;
top: 0rem;
margin-top: 0;
margin-left: 1rem;
border-color: none;

&:hover {
  cursor: pointer;
  filter: grayscale(50%);
}

${MEDIA.M_PHONE`
width: 12.5rem;
height: 12.5rem;
margin-left: 0rem;
  `};
`

export const Date = styled.p`
font-size: 1.75rem;
font-weight: 600;

margin-top: 3rem;
color: ${grey};
margin-bottom: 0;
margin-left: 12rem;
padding-bottom: 6rem;
text-shadow: 1px 1.5px 2.25px lightgrey;

${MEDIA.L_TABLET`
padding-bottom: 0;
  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
font-size: 1.5rem;
margin-left: 8rem;
margin-top: 3rem;
text-shadow: 1px 1.5px 1.5px lightgrey;

  `};

  ${MEDIA.M_PHONE`
margin-left: 4rem;
margin-top: 2rem;
font-size: 1.33rem;
// padding-bottom: 1rem;
  `};

  ${MEDIA.S_PHONE`
margin-left: 3rem;
font-size: 1rem;
  `};
`

export const Subtitle = styled.p`
font-size: 2.75rem;
font-family: "Termina";
text-transform: uppercase;
font-weight: 700;
width: 70%;
margin-left: 4rem;
color: grey;
margin-right: 10rem;
// margin: 0 auto;
text-shadow: 1px 1px 2.5px ${blue};

${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
font-size: 2.75rem;
margin-left: 3rem;

  `};


  ${MEDIA.PHONE`
font-size: 1.9rem;
margin-top: -1.5rem;
margin-left: 2.5rem;
text-shadow: 1px 1.5px 1.25px ${blue};

  `};

  ${MEDIA.M_PHONE`
font-size: 1.45rem;
line-height: 1.75rem;
// padding-top
margin-top: 0rem;
margin-left: 1rem;
width: 85%;
  `};

  ${MEDIA.S_PHONE`
font-size: 1.3rem;
line-height: 1.5rem;
  `};
`