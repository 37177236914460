import styled from 'styled-components'

import MEDIA from 'helpers/mediaTemplates';
import { blue, orange, grey, } from "../../constants/theme"


export const Container = styled.div`
color: black;
font-size: 1.5rem;
font-weight: 600;
color: ${blue};
right: 2.75rem;
max-width: 1000px;
z-index: 100;
// background: red;
top: 37.5rem;
height: 25rem;
width: auto;
position: fixed;
writing-mode: vertical-rl;
text-orientation: mixed;
text-transform: uppercase;
letter-spacing: .2rem;
font-family: 'Termina';

> * {
  margin-bottom: 2.5rem;
}

&::before {
    content: ${props => (props.tag === undefined || props.tag === null ?
    `NTHEOS` : `"${props.tag}"`)};
    background: rgba(0, 0, 0, 0.075);
padding: .75px;
border: 2.5px  solid rgba(2, 205, 252, 0.5);
    &:hover {
      cursor: pointer;
    }
}

${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
right: 0.75rem;
  `};


  ${MEDIA.PHONE`
top: 40rem;

  `};

  ${MEDIA.M_PHONE`
top: 35rem;
right: 0.3rem;
  `};

  ${MEDIA.S_PHONE`
top: 30rem;
  `};
`;
