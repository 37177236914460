import React from 'react';
import PropTypes from 'prop-types';
import { ArticleStatementStyle } from './statement.css';

const ArticleStatement = ({ children, as = '', size, pic }) => {
    return (
        <ArticleStatementStyle pic={pic} >
            {children}
        </ArticleStatementStyle>
    );
};

let textGenerator = (text) => {
    let textArray = text.split(" ");
    let firstHalf = textArray.slice(0, Math.floor(textArray.length / 2));
    let secondHalf = textArray.slice(Math.floor(textArray.length / 2), textArray.length)
    return (
        `${firstHalf} + <br/> + ${secondHalf}`
    )
}

ArticleStatement.propTypes = {
    as: PropTypes.string,
    size: PropTypes.oneOf(['large']),
};

export default ArticleStatement;