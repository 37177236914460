
import React from 'react';
import PropTypes from 'prop-types';
import {
    Container, Text, Opener
} from './shareTab.css';
import {
    LinkedinIcon, ClubhouseIcon,
    InstagramIcon, FacebookIcon, TwitterIcon, MailIcon, YoutubeIcon
} from '../icons/icons.css'
import { blue, orange, grey } from '../../constants/theme'

const ShareTab = ({ children, html, opener, as = '', shareInfo }) => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    console.log(shareInfo.title)
    const description = shareInfo.description
    const title = shareInfo.title
    return (
        <Container>
            <a rel="noreferrer" target="_blank"
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${title}&summary=${description}`}>
                <LinkedinIcon type="shareTab" color={blue} size="2.25rem" />
            </a>
            <a href="/">
                <FacebookIcon type="shareTab" color={blue} size="2.25rem" />
            </a>
            <a rel="noreferrer" target="_blank"
                href={`https://twitter.com/intent/tweet?text=Check%20this%20out%20from%20Ntheos&url=${url}`}>
                <TwitterIcon color={blue} type="shareTab" size="2.25rem" />
            </a>
            <a href="/">
                <MailIcon type="shareTab" color={blue} size="2.5rem" />
            </a>

        </Container >

    );
};

ShareTab.propTypes = {
    as: PropTypes.string,
    size: PropTypes.oneOf(['large']),
};

export default ShareTab;