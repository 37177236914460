import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { blue, orange, grey, } from "../../constants/theme"
import Img from 'gatsby-image'

export const VidIframe = styled.iframe`
padding-top: 10rem;
margin-left: 5rem;
// width: 66.6%; 
// height: 40%;
max-width: 600px; 
overflow: hidden; 
border-radius: 10px; 
background: transparent;
padding-bottom: 2.5rem;

${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
  `};


  ${MEDIA.PHONE`
width: 100%;
padding-top: 0rem;
margin-left: 0rem;
height: 40rem;

  `};

  ${MEDIA.M_PHONE`

  `};

  ${MEDIA.S_PHONE`

  `};
`;
