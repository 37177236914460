import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout/layout';
import { graphql } from 'gatsby';
import Section from '../components/Section/section'
import { ArticleSection } from '../components/Section/section.css'
import ArticleInfo from '../components/article/articleInfo/articleInfo'
import ArticleStatement from '../components/Statement/articleStatement'
import ArticleBody from '../components/article/articleBody/articleBody'
import { useEffect } from 'react'
import SEO from '../components/seo'
import ShareTab from '../components/shareTab/shareTab'
import Typeform from '../components/typeform/typeform'
import TypeformCTA from '../components/typeform/typeformCTA/typeformCTA'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { VidIframe } from '../components/vid/vid.css'


const Vid = ({ data }) => {


  console.log("vid data", data)
  const post = data.markdownRemark
  console.log(data)
  return (
    <Layout>
      <SEO
        description={post.frontmatter.description}
        image={
          post.frontmatter.cover !== null &&
            post.frontmatter.cover !== undefined ?
            post.frontmatter.cover.childImageSharp.fluid.src :
            `https://mag.ntheos.com/img/icon.jpeg`
        }
        title={post.frontmatter.title}
      />

      <ArticleStatement >
        {post.frontmatter.title}
      </ArticleStatement>
      <ArticleInfo
        cover={post.frontmatter.cover !== null ?
          post.frontmatter.cover.childImageSharp.fluid :
          null}
        author={post.frontmatter.author || "The Ntheos Team"}
        date={post.frontmatter.date}
        subtitle={post.frontmatter.description} />
      <Section type="article" tags={post.frontmatter.tags} size=''>
        <ArticleSection>
          <VidIframe title={post.frontmatter.title} id="ytplayer" type="text/html" width="640" height="360"
            src={`https://www.youtube.com/embed/${post.frontmatter.videoId}?autoplay=1&origin=http://example.com`}
            frameBorder="0" />

          {/* <ShareTab shareInfo={post.frontmatter} /> */}
          <ArticleBody contentType='vid' opener={post.frontmatter.opener} html={{ __html: post.html }} />
        </ArticleSection>

      </Section>
      {/* <Section type="cta" bg="white" section='Join the alpha' size='medium'>
        <TypeformCTA>
          <Typeform />
        </TypeformCTA>
      </Section> */}


    </Layout>
  )
};

Vid.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Vid;

export const query = graphql`
  query($slug: String!) {
            markdownRemark( fields: {slug: {eq: $slug } } ) {
            html
      frontmatter {
        title
        description
        date(formatString: "ddd MMMM Do, YYYY")
        videoId
        tags
        
        videoId
        featured
        
        cover {
            childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid
          }
          }
        }
      }
    }
  }
`
  ;
