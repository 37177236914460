import React from 'react';
import PropTypes from 'prop-types';
import {
    Container, Author, Date, Subtitle, AuthorPic
} from './articleInfo.css';
import KGPic from '../../../images/kg-sketch.jpg'
import NtheosPic from '../../../images/icon.png'
import OndrePic from '../../../images/ondre-sketch.png'

const ArticleInfo = ({ children, as = '', author, cover, date, subtitle }) => {
    console.log("img in article info: ", cover)

    return (
        <Container bg={cover !== null ? cover.src : ""}>
            <Subtitle>
                {subtitle}
            </Subtitle>
            <Author>
                by
                <br />
                <a href={author === "KG" ? `https://www.twitter.com/KG_NTH` :
                    author === "Ondre" ? `https://www.twitter.com/ONDRE_NTH` :
                        `https://www.instagram.com/ntheosmag`}>{author}
                </a>

                <a href={author === "KG" ? `https://www.twitter.com/KG_NTH` :
                    author === "Ondre" ? `https://www.twitter.com/ONDRE_NTH` :
                        `https://www.instagram.com/ntheosmag`}>
                    <AuthorPic src={author === "KG" ? KGPic :
                        author === "Ondre" ? OndrePic :
                            NtheosPic} />
                </a>
            </Author>
            <Date>
                Last updated on {date}
            </Date>
        </Container >

    );
};

ArticleInfo.propTypes = {
    as: PropTypes.string,
    size: PropTypes.oneOf(['large']),
};

export default ArticleInfo;