import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates';
import { blue, orange, grey, } from "../../../constants/theme"

export const Container = styled.div`
// background: lightblue;
margin-left: 2.5rem;
margin-top: 0;



${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
margin-left: 2rem;
width: 85%

  `};

  ${MEDIA.M_PHONE`

  `};

  ${MEDIA.S_PHONE`
  width: 82.5%

  `};
`

export const Text = styled.div`
font-size: 1.4rem;
font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
text-transform: none;
margin-right: 10rem;
line-height: 2.75rem;
padding-bottom: 0rem;
margin-bottom: 10rem;
letter-spacing: .66px;
font-weight: 300;
max-width: 1000px;
a {
  color: ${blue};
  font-weight: 600;
  text-decoration: none;
}
img {
  padding-top: 1rem;
  max-width: 80vw;
  min-width: 50vw;
}

h1 {
  font-family: "Termina";
  font-weight: 600;
  color: ${grey};
  font-size: 2.2rem;
  text-transform: uppercase;
  padding-bottom: 1rem;
  padding-top: 2.5rem;
}
 h2, h3, h4, h5 {
  font-family: "Termina";
  font-weight: 600;
  font-size: 1.75rem;
  text-transform: uppercase;
  padding-bottom: 1rem;
  padding-top: 2.5rem;
}                     
h3 {
  font-size: 1.5rem;
  color: ${orange}
}

ul {
  list-style-position: outside;
}

li {
  list-style-position: inside;
  text-indent: .25rem;
  margin-left: 4rem;
  
  &:before {
  position: relative;
  top: 2.5rem;
  left: -3.25rem;
    content: "->"; 
    color: ${orange};
    margin-right: 1rem;
}
}



${MEDIA.L_TABLET`
margin-top: 10rem;

h1{
  font-size: 1.66rem
}
 h2, h3 {
 
  font-size: 1.4rem;
  padding-top: 1rem;
}

  `};

${MEDIA.TABLET`



h1{
  font-size: 1.85rem;
}
 h2, h3 {
 
  font-size: 1.3rem;
  padding-top: 1rem;
}
  `};


${MEDIA.PHONE`
  margin: 0 auto;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: .33px;
    `};


${MEDIA.M_PHONE`
  margin: 0 auto;
  width: 99%;
  font-size: 13px;
  line-height: 1.9rem;
  letter-spacing: .33px;
  margin-left: -1.1rem;
  padding-bottom: 5rem;

  h1{
  font-size: 1.5rem;
  padding-bottom: 1.5rem;
  } 

  h2, h3 {

  font-size: 1.1rem;
  }

  li {
    margin-bottom: 2.5rem;
  list-style-position: inside;
  margin-left: 3rem;
  text-indent: .1rem;

    &:before {
      position: relative;
      top: 2rem;
      left: -2.5rem;
      margin-right: 1.75rem;
    }
    margin-bottom: 1.75rem;

    }
  `};

${MEDIA.S_PHONE`
margin-left: -1.5rem;
font-size: .9rem;
line-height: 1.25rem;
  `};


`


export const Opener = styled.p`
font-size: 2rem;
color: ${orange};
margin-left: 2rem;
font-family: 'Termina';
font-weight: 600;
// margin-top: 3rem;
margin-bottom: 8rem;

${MEDIA.L_TABLET`
margin-top: -3.5rem;


  `};

${MEDIA.TABLET`

  `};


${MEDIA.PHONE`
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: .33px;
  margin-top: -4.25rem;
  margin-bottom: 5rem;
    `};


${MEDIA.M_PHONE`
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: .33px;
  margin-left: -.25rem;
  `};

${MEDIA.S_PHONE`
font-size: .8rem;
margin-left: -1rem;
  `};


`

export const ArticleFooter = styled.p`
font-family: "Termina";
color: ${blue};
margin: 0;
font-weight: 700;
font-size: 1.2rem;
padding-bottom: 2rem;
`