
import React from 'react';
import PropTypes from 'prop-types';
import {
    Container, Text, Opener, ArticleFooter,
} from './articleBody.css';

const ArticleBody = ({ children, html, contentType, opener, as = '', author, date, subtitle }) => {
    console.log("html", html)
    return (
        <Container>
            <Opener>{opener}</Opener>
            <Text dangerouslySetInnerHTML={html} />
            <ArticleFooter>Thank you for
                {contentType === 'vid' ? ' viewing' :
                    contentType === 'pod' ? ' listening' :
                        ' reading'
                }.
            </ArticleFooter>
        </Container>

    );
};

ArticleBody.propTypes = {
    as: PropTypes.string,
    size: PropTypes.oneOf(['large']),
};

export default ArticleBody;